.wallet {
  --bg-color: #ffbf00;
  --bg-color-light: #f0e7ff;
  --text-color-hover: #fff;
  --box-shadow-color: 0 0 5px #fdbe01, 0 0 40px #ff00fff1, 0 0 40px #fdbe01;
}

.card2 {
  width: 270px;
  min-height: 320px;
  background: #ffffff00;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 70px;
  border: 2px dashed #ffbf00;
  padding: 15px 15px;
  color: rgb(39, 39, 39);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.1s ease-out;
  text-decoration: none;
}
.innercard {
  height: 320px !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 70px;
  padding-bottom: 40px;
  overflow: visible;
}
.circle2 {
 
  border-radius: 50%;
  background: #ffffff;
  
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
  margin-top: -30px;
}
.card2:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 0 5px #ff00ff88, 0 0 10px #fdbe01, 0 0 10px #00000088;
  color: #2e4a53 !important;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
.circle2 img {
  z-index: 2;
}
.card2:hover .overlay2 {
  transform: scale(20) translateZ(0);
}

.card2:hover .circle2 {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card2:hover .circle2:after {
  background: var(--bg-color-light);
}

/* .card2:hover p {
  color: var(--text-color-hover);
} */

.card2:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.card2 p {
  font-size: 17px;
  margin-top: 10px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}
.card2 h5 {
  z-index: 1000;
  position: relative;
  transition: color 0.3s ease-out;
}
.card2 h6 {
  z-index: 1000;
  position: relative;
  transition: color 0.3s ease-out;
}

.card2 h5::before {
  content: "";
  height: 3px;
  background-color: #fdbe01;
  width: 50%;
  position: absolute;
  bottom: -6px;
  transform: translateX(-50%);
  left: 50%;
  transition: 0.5s ease-in-out;
}

.card2:hover h5::before {
  content: "";
  height: 3px;
  background-color: #2e4a53;
  width: 50%;
  position: absolute;
  bottom: -6px;
  transform: translateX(-50%);
  left: 50%;
  transition: 0.5s ease-in-out;
}


.overlay2 {
  width: 90px;
  position: absolute;
  height: 45px;
  border-radius: 45%;
  background: var(--bg-color);
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  transition: transform 0.5s ease-in-out;
}
.circle2:after {
  content: "";
  width: 87px;
  height: 87px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out;
}
