.PaymentSection2Container {
  padding: 30px;
  border: 4px solid #ffbf00;
  border-radius: 20px;

  width: 95%;
  margin: 30px auto;
}
.OpenSans {
  color: black;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .PaymentSection2Container {
    padding: 20px 0px;
    border: 4px solid #ffbf00;
    border-radius: 20px;

    width: 100%;
    margin: 30px auto;
  }
  .Calculating-Your-Future{
    font-size: 20px;
  }
  .OpenSans {
    color: black;
    font-size: 18px;
  }
}

