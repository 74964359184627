.Estimationbanner {
  background-color: rgb(255, 255, 255);
  background-image: url("../../Images//Section.png");
  background-position: center center;
  background-size: 100% 100%;
  padding: 90px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 30px;
  min-height: 370px;
  position: relative;
}

.Estimationbanner div h2 {
  color: #ffbf00;
}

.Estimationbanner div p {
  color: white;
}
.Estimationbanner div {
  width: 50%;
}

.QuantitiesMeterial:hover {
  transform: scale(1.080);
  transition: 0.3s linear;
}

.QuantitiesMeterial {
  transform: scale(1);
  transition: 0.3s linear;
}

@media screen and (max-width: 990px) {
  .Estimationbanner {
    background-color: rgb(255, 255, 255);
    background-image: url("../../Images//Section.png");
    background-position: center center;
    background-size: cover;
    padding: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 30px;
    min-height: 350px;
  }
}

@media screen and (max-width: 700px) {
  .Estimationbanner div {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .Estimationbanner div {
    width: 70%;
  }
}

@media screen and (max-width: 400px) {
  .Estimationbanner div {
    width: 70%;
  }
  .Estimationbanner {
    background-color: rgb(255, 255, 255);
    background-image: url("../../Images//Section.png");
    background-position: center center;
    background-size: cover;
    padding: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 30px;
    min-height: 350px;
  }
}
