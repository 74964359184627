.ContectHeroSection {
  height: 100vh;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formContainer {
  width: 320px;
  min-width: 100%;
}
.inpitFields {
  width: 100%;
  margin: 05px 0px;
  height: 40px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0px 10px;
}
.textAreaFields {
  padding: 02px 8px;
  margin: 05px 0px;
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
}
.submitBtn {
  padding: 10px 30px;
  animation: blink 1.4s linear infinite;
  background-color: #ffbf00 !important;
  border: none !important;
  font-weight: 600 !important;
}

@media screen and (max-width: 768px) {
  .formContainer {
    width: 90%;
    margin: auto auto;
  }
}




