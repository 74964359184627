.scroll-container::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

.scroll-container::-webkit-scrollbar-track {
  background: #2e4a53; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ffbf00; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Space around the thumb to give a padding effect */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #c29100; /* Color of the thumb when hovered */
}

.Navbar-Custom {
  padding: 0px 15px !important;
  box-shadow: 0px 2px 6px 2px rgb(0, 0, 0);
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  width: 80%;
  margin: auto auto;
  background-color: #2e4a53;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
  min-height: 70px;
  transition: 0.7s cubic-bezier(1, -0.64, 0.69, 1.75);
  transition-delay: 0.5s;
}
.logotransition {
  transition: 0.7s cubic-bezier(1, -0.64, 0.69, 1.75) !important;
  transition-delay: 0.3s;
}
.Navbar-Custom:hover {
  background-color: rgb(255, 255, 255) !important;
  width: 100%;
  transition: 0.7s cubic-bezier(1, 0.03, 0, 1);

  clip-path: none;
}

.Navbar-Custom:hover .nav-link a {
  color: #000000;
  transition: 0.7s cubic-bezier(1, -0.64, 0.69, 1.75);
  transition-delay: 0s !important;
}
.Navbar-Custom:hover .nav-link .Nav-a {
  color: #000000 !important;
  transition: 0.7s cubic-bezier(1, -0.64, 0.69, 1.75);
}
.hover-dropdown:hover .dropdown-menu {
  display: block !important;
}

.dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-menu {
  top: 0;
  left: 100% !important;
  margin-top: -1px;
}
.Nav-a:hover::before {
  transition: 0.3s linear;
  width: 100% !important;
}
.Nav-a {
  position: relative;
}
.Nav-a::before {
  content: "";
  text-align: center;
  position: absolute;
  bottom: -5px;
  width: 0%;
  background-color: #ffbf00;
  height: 3px;
  transition: 0.2s linear;
}
.Nav-a.active::before {
  width: 100%;
  /* color: white !important; */
}
.Nav-a.active {
  color: white !important;
}
.nav-link {
  font-size: 17px !important;
  /* font-weight: 500 !important; */
  text-decoration: none;
  color: rgb(36, 36, 36) !important;
  padding: 0px 10px !important;
}
.nav-link a {
  position: relative;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: 0.7s cubic-bezier(0, -0.8, 0.8, 2.5) !important;
  transition-delay: 0.7s !important;
  display: inline-block;
  text-align: center;
}
/* }
.nav-link.active a {
  color: #ffffff;
  transition: 0.2s;
  /* font-weight: 600;} */

.GetEstimation-Btn {
  animation: blink 1.4s linear infinite;
  background-color: #ffbf00 !important;
  border: none !important;
  font-weight: 600 !important;
}
@keyframes blink {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.header {
  width: 100%;
  height: 50px;
  background-color: #028ec9;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1002;
  top: 0;
}
.Off40 {
  margin-left: -150px;
}
.ServicesDropDownContainer:hover .ServicesDropDown {
  max-height: 200px;
  transition: 0.3s linear;
  box-shadow: 2px 2px 15px 4px rgb(148, 148, 148),
    /* 2px 2px 15px 4px rgba(156, 156, 156, 0.486); */;
  border: 1px solid rgb(175, 175, 175);
  background-color: white;
}
.ServicesDropDown {
  max-height: 0px;
  overflow: auto;
  position: absolute;
  background-color: white;
  left: -70px;
  width: 300px;
  transition: 0.5s linear;
  transition-delay: 0.3s;
  top: 30px;
  border-radius: 0px;
}
.ServicesDropDown ul li a {
  list-style-type: none;
  display: inline-block;
  width: 100%;
  margin: 0px;
  padding: 0px 5px;
  color: black !important;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 450 !important;
}
.ServicesDropDown ul li a:hover {
  background-color: #ffbf00;
  color: #2e4a53 !important;
  transition: 0.2s linear;
}

.ServicesDropDown ul li:hover {
  /* color: white; */
}
.ServicesSubDropDownContainer:hover .ServicesSubDropDown {
  max-height: 65px;
  opacity: 1;
  transition: 0.3s linear;
  transition-delay: 0.3s;
  padding: 05px 10px;
}

.ServicesSubDropDown {
  max-height: 0px;
  transition: 0.3s ease;
  background-color: #2e4a53;
  /* color: white; */
  opacity: 0;
  border-radius: 0px;
  padding: 0px 10px;
  transition-delay: 6s;
}
.ServicesDropDown .ServicesSubDropDown li a {
  color: white !important;
}
.TradeDropDwonContainer {
  position: relative;
}
.TradeDropDwonContainer:hover .TradeDropDwon {
  max-height: 85vh;
  transition: 0.3s linear;
  box-shadow: 2px 2px 15px 4px rgb(148, 148, 148),
    /* 2px 2px 15px 4px rgba(156, 156, 156, 0.486); */;
  border: 1px solid rgb(175, 175, 175);
}
.TradeDropDwon {
  max-height: 0px;
  overflow: auto;
  position: absolute;
  top: 32px;
  left: -40px;
  background-color: white;

  width: 340px;
  transition: 0.4s linear;
  transition-delay: 0.5s;
  border-radius: 0px;
}
.TradeDropDwon ul li a {
  width: 100% !important;
  display: inline-block;
  color: black !important;
  padding: 0px 5px;
  font-weight: 450 !important;
  margin-top: 2px;
}
.TradesSubDropDown2 .TradesSubDropDown li a {
  color: white !important;
}
.TradeDropDwon ul li {
  list-style-type: none;
  text-transform: capitalize;
  margin: 0px;
  padding: 0px 5px;
  border-radius: 5px;
  font-size: 16px;
}

.TradeDropDwon ul li a:hover {
  background-color: #ffbf00;
  color: #2e4a53 !important;
  font-weight: 500;
  border-radius: 0px;
  transition: 2s linear;
}

.TradesSubDropDown li a {
  color: white !important;
}

.TradesSubDropDown2:hover .TradesSubDropDown {
  max-height: 120px;
  padding: 03px 5px !important;
  overflow: auto;
  transition: 0.2s linear;
  transition-delay: 0.2s;
  opacity: 1;
  color: white !important;
}

.TradesSubDropDown {
  opacity: 1;
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease;
  background-color: #2e4a53 !important;
  color: white;
  border-radius: 0px;
  transition-delay: 6s;
}
.TradesSubDropDown ul li a {
  color: white;
  border-radius: 3px;
  font-size: 16px;
}
.navbar-toggler {
  background-color: white !important;
}

@media screen and (max-width: 1290px) {
  .Navbar-Custom {
    transition: 0.3s;
    padding: 0px 15px !important;
    box-shadow: 0px 2px 6px 2px rgb(0, 0, 0);

    width: 90%;
    background-color: #2e4a53;

    min-height: 70px;
    transition: 0.7s cubic-bezier(1, -0.64, 0.69, 1.75);
    transition-delay: 0.3s;
  }
}

@media screen and (max-width: 1200px) {
  .Navbar-Custom {
    transition: 0.3s;
    padding: 0px 15px !important;
    box-shadow: 0px 2px 6px 2px rgb(0, 0, 0);
    /* position: absolute !important; */
    width: 100%;
    /* background-color: #2e4a53;
    margin: auto auto !important;
    min-height: 70px;
    transition: 0.7s cubic-bezier(1, -0.64, 0.69, 1.75);
    transition-delay: 0.3s; */
    /* position: sticky; */
    /* top: 0px !important; */
  }
  .nav-link {
    font-size: 18px !important;
    text-decoration: none;
    color: black !important;
    padding: 0px 10px;
  }
}
@media screen and (max-width: 1100px) {
  .Off40 {
    margin-left: 0px;
  }
  .nav-link {
    font-size: 16px !important;
    text-decoration: none;
    text-align: center;
    color: black !important;
    padding: 0px 8px !important;
  }
}
@media screen and (max-width: 990px) {
  .Navbar-Custom {
    background-color: #2e4a53 !important;
    transition: 0.3s;
    padding: 0px 15px !important;
    box-shadow: 0px 2px 6px 2px rgb(0, 0, 0);
    width: 100%;
    clip-path: none;
    left: 0%;
    transform: none !important;
  }

  .nav-link {
    font-size: 18px !important;
    text-decoration: none;
    text-align: center;
    color: black !important;
    padding: 0px 8px !important;
  }
  .nav-link a {
    position: relative;
    text-decoration: none;
    color: black !important;

    display: inline-block;
    text-align: center;
  }

  .TradeDropDwon {
    max-height: 0px;
    overflow: auto;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    width: 300px;
    transition: 0.4s linear;
    transition-delay: 0.5s;
    border-radius: 0px;
    z-index: 3;
  }

  .ServicesDropDown {
    max-height: 0px;
    overflow: auto;
    position: absolute;
    background-color: white;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    transition: 0.5s linear;
    transition-delay: 0.3s;
    top: 32px;
    z-index: 3;
    border-radius: 0px;
  }

  .TradeDropDwonContainer:hover .TradeDropDwon {
    max-height: 90vh;
    transition: 0.3s linear;
    box-shadow: 2px 2px 15px 4px rgb(148, 148, 148),
      /* 2px 2px 15px 4px rgba(156, 156, 156, 0.486); */;
    border: 1px solid rgb(175, 175, 175);
  }
}

@media screen and (max-width: 850px) {
  .header {
    width: 100%;
    height: auto;
    background-color: #028ec9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 05px;
    align-items: center;
  }
  .header div {
    padding: 2px;
  }
}

@media screen and (max-width: 500px) {
  .navbar-brand img {
    width: 150px;
    height: 40px;
  }
}
