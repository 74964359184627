.not-found-parent {
    min-height: 66vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-parent p {
    font-weight: bold;
    font-size: 30px;
}