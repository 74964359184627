.GetAquoteBtn {
  background-color: white;
  color: black;
  margin: 10px;
  padding: 5px 25px;
}
.CallBtn {
  background-color: #ffbf00;
  color: white;
  margin: 10px;
  padding: 5px 15px;
  border: 1px solid #ffbf00;
}
.GrayBg {
  width: 100%;
  background-color: #5f6265;
  padding: 50px;
  color: white;
}
.Project_ExpertiseBtn {
  border: 2px solid #1c2752;
  padding: 10px 25px;
  color: #1c2752;
  font-weight: 500;
  background-color: #ffb342;
  
  
  transition: 0.5s ease-in-out;
}
.Project_ExpertiseBtn.active {
  border: 2px solid #1c2752;
  padding: 10px 25px;
  color: #1c2752;
  font-weight: 500;
  background-color: #ffffff;
  transition: 0.5s ease-in-out;
}
.Project-ExpertiseDetails {
  width: 70%;
  margin: 0 auto;
  box-shadow: 3px 3px 10px black;
  padding: 20px 20px;
  margin-top: -300px;
  background-color: white !important;
  position: relative;
  z-index: 2;
}

.OurProjectExpertise {
  height: 550px;
}
.GreatExperience{
  background-color: #2E4A53;
  padding: 30px 20px;
  
}
@media screen and (max-width: 768px) {
  .Project-ExpertiseDetails {
    width: 80%;
    margin: 0 auto;
    box-shadow: 3px 3px 10px black;
    padding: 20px 20px;
    margin-top: -300px;
    background-color: white !important;
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: 480px) {
  .Project-ExpertiseDetails {
    width: 97%;
    margin: 0 auto;
    box-shadow: 3px 3px 10px black;
    padding: 10px 10px;
    margin-top: -200px;
    background-color: white !important;
    position: relative;
    z-index: 2;
  }
  .GrayBg {
    width: 100%;
    background-color: #5f6265;
    padding: 10px 0px;
    color: white;
  }
}


































