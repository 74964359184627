.coming-soon-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.coming-soon-parent .content-div {
  text-align: center;
}

.coming-soon-parent h1 {
  font-size: 4rem;
  color: #ffbf00;
}
.coming-soon-parent p {
  font-size: 1.5rem;
  color: #333;
}

.coming-soon-parent .highlight {
  color: #ffbf00;
}
