.sectio2Container {
  background-image: url("../../Images//About@ndSectionBg.jpeg");
  background-size: cover;
  width: 100%;
  opacity: 1;
  min-height: 100vh;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
  color: #ffffff;
}
.slide {
  width: 70%;
  margin: 10px auto;
  background-color: #0000006e;
  padding: 10px 20px;
  border-radius: 20px;
  border: 2px solid white;
  gap: 20px;
  box-shadow: 0 0 5px #fdbe01, 0 0 30px #ff00ff65, 0 0 10px #fdbe01;
}
.shadowed-text {
  text-shadow: 1px 1px 2px black;
}
.section3Container {
  width: 60%;
  margin: auto auto;
  position: relative;
}
.CenteralLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}
.circulecontainer {
  width: 250px;
  min-height: 250px;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid rgb(216, 208, 194);
  background-color: #ffffff;
  box-shadow: inset 0.5px 0.5px 10.5px 7px #f1e9d1;
  text-align: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
